


























































































import Vue from "vue";
import Component from "vue-class-component";
import Drawer from "@/components/Drawer.vue";
import OptionsMenu from "@/components/OptionsMenu.vue";
import { Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import { mapMutations, mapState } from "vuex";

@Component({
  components: {
    Drawer,
    OptionsMenu
  },
  computed: {
    ...mapState(["drawer"])
  },
  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER"
    })
  }
})
export default class App extends Vue {
  trackName = "";
  playerName = "";
  //Watch route change.
  //Empty trackName/playerName if new route isn't Map/Player
  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newRoute: Route) {
    if (newRoute.name !== "Map") this.trackName = "";
    if (newRoute.name !== "Player") this.playerName = "";
  }

  mounted() {
    //Get lang from localStrorage
    if (localStorage.Lang != null) this.$i18n.locale = localStorage.Lang;

    //Get theme from localStorage
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme == "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    }

    //Get show cps from localStorage
    const showCps = localStorage.getItem("show_cps");
    if (showCps) {
      if (showCps == "true") {
        this.$store.state.showCps = true;
      } else {
        this.$store.state.showCps = false;
      }
    }

    //Get default rows per page from localStorage
    if (localStorage.getItem("default_row_per_page") != null)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.$store.state.rowsPerPage = +localStorage.getItem(
        "default_row_per_page"
      )!;
  }

  get drawer() {
    return this.$store.state.drawer;
  }

  get trackRecordsRoute() {
    return this.$route.name == "Map";
  }
  //get trackname value from maprecords child component
  getTrackName(value: string) {
    this.trackName = value;
  }
  //get playername value from playerrecords child component
  getPlayerName(value: string) {
    this.playerName = value;
  }
}
